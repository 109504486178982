import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-RPN66RKRVS';

export const initGA = () => {
    ReactGA.initialize(TRACKING_ID);
};

export const logPageView = (path) => {
    ReactGA.send({ hitType: 'pageview', page: path });
};

export const logEvent = (category, action, label = '') => {
    ReactGA.event({ category, action, label });
};