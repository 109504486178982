// components/dialog/LoginDialog.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { inAppWallet } from 'thirdweb/wallets';
import { useConnect } from 'thirdweb/react';
import { client } from '../../utils/client';
import { polygon } from 'thirdweb/chains';
import { closeModal } from '../../store/Login/modalSlice';
import Image from 'next/image';
import MainButton from '../buttons/mainButton';
import { useAppDispatch } from '../../store/store';
import { addAlert } from '../../store/alert/alertSlice';
import { AlertStatus } from '../../utils/alert.type';
import { addLoading } from '../../store/loading/loadingSlice';
import {logEvent} from '../../utils/analytics';

const API_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3000/api';

interface TokenResponse {
    accessToken: string;
    refreshToken: string;
}

interface PayloadResponse {
    payload: string;
}

interface LoginDialogProps {
    prefilledReferral?: string | null;
}

type RegistrationPhase = 'form' | 'code';

const LoginDialog: React.FC<LoginDialogProps> = ({ prefilledReferral }) => {
    const dispatch = useAppDispatch();
    const { connect } = useConnect();

    const [activeTab, setActiveTab] = useState<'login' | 'register'>(
        prefilledReferral ? 'register' : 'login'
    );

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerRepeat, setRegisterRepeat] = useState('');
    const [registerReferral, setRegisterReferral] = useState(prefilledReferral || '');
    const [registrationCode, setRegistrationCode] = useState('');
    const [registrationPhase, setRegistrationPhase] = useState<RegistrationPhase>('form');

    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (prefilledReferral) {
            setActiveTab('register');
            setRegisterReferral(prefilledReferral);
        }
    }, [prefilledReferral]);


    const handleLogin = async () => {
        if (email.trim() === '' || password.trim() === '') {
            setMessage('Please fill in the Email and Password fields.');
            return;
        }

        setIsLoading(true);
        dispatch(addLoading(true));
        setMessage('');
        try {
            const tokenResponse = await getTokenFromYourServer(email, password);
            if (!tokenResponse || !tokenResponse.accessToken) {
                setMessage('Incorrect login details');
                setIsLoading(false);
                return;
            }
            const payload = await verifyAccessToken(tokenResponse.accessToken);
            if (payload) {
                logEvent('User', 'Login', 'LoginForm');
                await connectWithPayload(payload);
            } else {
                const newAccessToken = await refreshAccessToken(tokenResponse.refreshToken);
                if (newAccessToken) {
                    const newPayload = await verifyAccessToken(newAccessToken);
                    if (newPayload) {
                        await connectWithPayload(newPayload);
                    } else {
                        setMessage('Token verification error');
                    }
                } else {
                    setMessage('Token update error');
                }
            }
            dispatch(closeModal());
        } catch (error) {
            console.error('Login error:', error);
            setMessage('Something went wrong, please try again');
            dispatch(addLoading(false));
            dispatch(
                addAlert({
                    title: 'Important notification',
                    message: 'Something went wrong, please try again',
                    type: AlertStatus.error,
                })
            );
        } finally {
            setIsLoading(false);
        }
    };
    const handleSendCode = async () => {
        if (
            registerEmail.trim() === '' ||
            registerPassword.trim() === '' ||
            registerRepeat.trim() === ''
        ) {
            setMessage('Please fill in all required fields.');
            return;
        }
        if (registerPassword !== registerRepeat) {
            setMessage('Passwords do not match.');
            return;
        }
        setIsLoading(true);
        dispatch(addLoading(true));
        setMessage('');
        try {
            await axios.post(`${API_URL}/register`, {
                email: registerEmail,
                password: registerPassword,
                referrerId: registerReferral || null,
            });
            setMessage('Verification code sent successfully!');
            setRegistrationPhase('code');
        } catch (error: any) {
            console.error('Registration error:', error);
            setMessage(error.response?.data?.error || 'Registration failed');
        } finally {
            setIsLoading(false);
            dispatch(addLoading(false));
        }
    };
    const handleRegistration = async () => {
        if (registrationCode.trim() === '') {
            setMessage('Please enter the verification code.');
            return;
        }
        setIsLoading(true);
        dispatch(addLoading(true));
        setMessage('');
        try {

            const response = await axios.post(`${API_URL}/verify-code`, {
                email: registerEmail,
                password: registerPassword,
                code: registrationCode,
            });

            const serverResponse = response.data;
            if (serverResponse.message) {
                setMessage(serverResponse.message);
                setEmail(registerEmail);
                setPassword(registerPassword);
                setActiveTab('login');
                logEvent('User', 'Registration', 'RegistrationForm');
            } else {
                setMessage(serverResponse.error || 'Registration failed');
            }
        } catch (error: any) {
            console.error('Error verifying code:', error);
            setMessage(error.response?.data?.error || 'Verification failed');
        } finally {
            setIsLoading(false);
            dispatch(addLoading(false));
        }
    };


    // Utils
    const getTokenFromYourServer = async (email: string, password: string): Promise<TokenResponse | null> => {
        try {
            const { data } = await axios.post<TokenResponse>(`${API_URL}/login`, { email, password });
            localStorage.setItem('access_token', data.accessToken);
            localStorage.setItem('refresh_token', data.refreshToken);
            return data;
        } catch (error) {
            console.error('Error in getTokenFromYourServer:', error);
            setIsLoading(false);
            dispatch(addLoading(false));
            return null;
        }
    };

    const verifyAccessToken = async (accessToken: string): Promise<string | null> => {
        try {
            const { data } = await axios.post<PayloadResponse>(`${API_URL}/verification-token`, { accessToken });
            return data.payload;
        } catch (error) {
            console.error('Error in verifyAccessToken:', error);
            return null;
        }
    };

    const refreshAccessToken = async (refreshToken: string): Promise<string | null> => {
        try {
            const { data } = await axios.post<{ accessToken: string }>(`${API_URL}/refresh-token`, { refreshToken });
            localStorage.setItem('access_token', data.accessToken);
            return data.accessToken;
        } catch (error) {
            console.error('Error in refreshAccessToken:', error);
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            return null;
        }
    };

    const connectWithPayload = async (payload: string) => {
        try {
            const wallet = await connect(async () => {
                const walletInstance = inAppWallet();
                await walletInstance.connect({
                    client,
                    strategy: 'auth_endpoint',
                    payload: payload,
                    chain: polygon,
                });
                return walletInstance;
            });
            dispatch(addLoading(false));
            console.log('Wallet connected successfully! Address: ', wallet?.getAccount()?.address);
        } catch (error) {
            console.error('Failed to connect wallet:', error);
            setMessage('Failed to connect wallet');
        }
    };

    return (
      <div className="fixed inset-0 z-[50] flex items-center justify-center">
        {/* Overlay */}
        <div className="absolute inset-0 bg-black opacity-50" onClick={() => dispatch(closeModal())} />
        {/* Modal container */}
        <div className="relative rounded-lg shadow-lg p-8 mx-4 max-w-[1200px] max-h-[700px] w-full h-full">
          {/* Background image */}
          <Image
            src="/login/NewLoginPanel.webp"
            alt="Login Background"
            fill
            className="object-cover object-center rounded-lg"
            priority
          />

          <div className="relative z-10 mt-40">
            <div className="flex justify-center mb-4">
              <button onClick={() => setActiveTab('login')} className="flex-shrink-0 relative">
                <Image
                    src={activeTab === 'login' ? '/login/LoginTabActive.webp' : '/login/LoginTabDisabled.webp'}
                    alt="Login Tab"
                    width={120}
                    height={50}
                    className="rounded"
                />
                <span className="absolute inset-0 flex items-center justify-center text-lg font-georgia font-[700] text-white select-none ml-4">
                  Login
                </span>
              </button>
              <button onClick={() => setActiveTab('register')} className="flex-shrink-0 relative ml-0">
                <Image
                    src={activeTab === 'register' ? '/login/RegistrationTabActive.webp' : '/login/RegistrationTabDisabled.webp'}
                    alt="Register Tab"
                    width={120}
                    height={50}
                    className="rounded"
                 />
                <span className="absolute inset-0 flex items-center justify-center text-lg font-georgia font-[700] text-white select-none mr-4">
                  Register
                </span>
              </button>
            </div>

            {activeTab === 'login' && (
            <div className="flex flex-col items-center space-y-3">
              <input
                type="email"
                placeholder="Email"
                className="w-[250px] p-2 border rounded"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                className="w-[250px] p-2 border rounded"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <MainButton
                borderColor="white"
                stopColorOne="#006400"
                stopColorTwo="#228B22"
                className="w-[250px] px-4 py-2 text-white rounded text-[18px]"
                onClick={handleLogin}
              >
                {isLoading ? 'Connecting...' : 'Login'}
              </MainButton>
            </div>
                )}

            {activeTab === 'register' && (
            <div className="flex flex-col items-center space-y-3">
              {registrationPhase === 'form' ? (
                <>
                  <input
                    type="email"
                    placeholder="Email*"
                    className="w-[250px] p-2 border rounded"
                    value={registerEmail}
                    onChange={(e) => setRegisterEmail(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Password*"
                    className="w-[250px] p-2 border rounded"
                    value={registerPassword}
                    onChange={(e) => setRegisterPassword(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Repeat Password*"
                    className="w-[250px] p-2 border rounded"
                    value={registerRepeat}
                    onChange={(e) => setRegisterRepeat(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Referral ID"
                    className="w-[250px] p-2 border rounded"
                    value={registerReferral}
                    onChange={(e) => setRegisterReferral(e.target.value)}
                  />
                  <MainButton
                    borderColor="white"
                    stopColorOne="#006400"
                    stopColorTwo="#228B22"
                    className="w-[250px] px-4 py-2 text-white rounded text-[18px]"
                    onClick={handleSendCode}
                  >
                    Send code
                  </MainButton>
                </>
                ) : (
                  <>
                    <input
                            type="text"
                            placeholder="Enter verification code"
                            className="w-[250px] p-2 border rounded"
                            value={registrationCode}
                            onChange={(e) => setRegistrationCode(e.target.value)}
                        />
                    <MainButton
                            borderColor="white"
                            stopColorOne="#006400"
                            stopColorTwo="#228B22"
                            className="w-[250px] px-4 py-2 text-white rounded text-[18px]"
                            onClick={handleRegistration}
                        >
                      Registration
                    </MainButton>
                  </>
                )}
            </div>
                    )}
            {message && <p className="flex justify-center mt-8 text-red-500">{message}</p>}
          </div>
        </div>
      </div>
    );
};

export default LoginDialog;