import '../styles/globals.css';
import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ThirdwebProvider } from 'thirdweb/react';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import Head from 'next/head';
import Layout from '../components/layout';
import { initGA, logPageView } from '../utils/analytics';

export default function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();

    useEffect(() => {
        initGA();

        const handleRouteChange = (url: string) => {
            logPageView(url);
        };

        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
      <ThirdwebProvider>
        <Provider store={store}>
          <Head>
            <title>Decision Card Game</title>
            <meta
                name="description"
                content="Innovative card game that merges NFTs, Battle for Crypto, Staking, Mining, DeFi, & GameFi."
            />
          </Head>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Provider>
      </ThirdwebProvider>
    );
}